<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between pb-3">
      <!-- Título y botón -->
      <h4 class="mb-0 text-primary">Categoría</h4>
      <Preloader :visible="cargando" />
      <button 
          class="btn btn-primary d-flex align-items-center gap-2" 
          @click="nuevoItem">
          <i class="fas fa-plus"></i>
          <span>Nuevo</span>
      </button>
  </div>
    <!-- Cuerpo de la tarjeta con la tabla de categorías -->
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-4">
        <table class="table table-bordered table-striped mb-0" ref="itemtabla">
          <thead>
            <tr>
              <th class="text-center px-3 py-2">ID</th>
              <th class="text-center px-3 py-2">Nombre</th>
              <th class="text-center px-3 py-2">Descripción</th>
              <th class="text-center px-3 py-2">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(categoria, index) in categorias" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-start">{{ categoria.nombre }}</td>
              <td class="text-start">{{ categoria.descripcion }}</td>
              <td class="text-center">
                <button @click="verCategoria(categoria)" class="badge badge-sm bg-gradient-success">
                  <i class="far fa-eye" aria-hidden="true"></i>
                </button>
                <button @click="editarCategoria(categoria)" class="badge badge-sm bg-gradient-warning">
                  <i class="far fa-edit" aria-hidden="true"></i>
                </button>
                <button @click="desactivarCategoria(categoria.id)" class="badge badge-sm bg-gradient-danger">
                  <i class="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal para crear o editar una categoría -->
    <div
      class="modal fade"
      id="principalModal"
      tabindex="-1"
      aria-labelledby="principalModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <!-- Cabecera del modal -->
          <div class="modal-header">
            <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <form id="premioForm">
              <div class="mb-3">
                <label for="nombre" class="form-label">Nombre de Categoría</label>
                <input type="text" class="form-control" id="nombre" v-model="form.nombre" />
                <div class="text-danger">{{ formErrors.nombre }}</div>
              </div>
              <div class="mb-3">
                <label for="descripcion" class="form-label">Descripción</label>
                <textarea class="form-control" id="descripcion" v-model="form.descripcion"></textarea>
                <div class="text-danger">{{ formErrors.descripcion }}</div>
              </div>
              <div class="mb-3">
                <label for="imagen" class="form-label">Imagen</label>
                <input type="file" class="form-control" @change="handleFileUpload" />
                <div class="text-danger">{{ formErrors.imagen }}</div>
                <div v-if="form.imagen">
                  <img :src="form.imagen" alt="Imagen de la categoria" width="100" />
                </div>
              </div>
            </form>
          </div>

          <!-- Pie del modal -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
            <button type="button" class="btn btn-primary" @click="guardarCategoria">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "../components/Preloader.vue";
import CategoriaService from "../../services/CategoriaService";

export default {
  name: "Categorias",
  data() {
    return {
      categorias: [],
      cargando: false,
      form: {
        nombre: "",
        imagen: "",
        descripcion: "",
        estado: 1,
      },
      formErrors: {},
      file: null,
      modalTitle: "",
      currentCategoriaId: '',
    };
  },
  created() {
    this.fetchCategorias();
  },
  components: {
    Preloader,
  },
  methods: {
    async fetchCategorias() {
      try {
        this.cargando = true;
        const response = await CategoriaService.getCategoriasActivas();
        this.categorias = response.data.data;
      } catch (error) {
        console.error("Error fetching categorias:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las categorías.",
        });
      } finally {
        this.cargando = false;
      }
    },
    verCategoria(categoria) {
      const imagenUrl = process.env.VUE_APP_API_URL + categoria.imagen;
      const detalles = `
      <div style="display: flex; flex-wrap: wrap;">
      <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
        <p><strong>Nombre:</strong> ${categoria.nombre}</p>
        <p><strong>Descripción:</strong> ${categoria.descripcion}</p>
        <p><strong>Imagen:</strong><img src="${imagenUrl}" alt="${categoria.nombre}" width="100" /></p>
      `;
      this.$swal({
        icon: "info",
        title: "Detalles de la categoría",
        html: detalles,
      });
    },
    async desactivarCategoria(id) {
       // Función para desactivar una categoría
      try {
        await CategoriaService.desactivarCategoria(id);
        this.$swal({
          icon: "success",
          title: "Categoría Desactivada",
          text: "La categoría ha sido desactivada",
        }).then(() => {
          this.fetchCategorias();
        });
      } catch (error) {
        console.error("Error desactivando la categoría:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al desactivar la categoría.",
        });
      }
    },
    editarCategoria(categoria) {
      const imagenUrl = process.env.VUE_APP_API_URL + categoria.imagen;
      this.modalTitle = "Editar Categoría";
      this.currentCategoriaId = categoria.id;
      this.form = {
        nombre: categoria.nombre,
        descripcion: categoria.descripcion,
        imagen: imagenUrl,
        estado: categoria.estado,
      };
      this.showModal();
    },
    async guardarCategoria() {
      const formData = new FormData();
      formData.append("nombre", this.form.nombre);
      formData.append("descripcion", this.form.descripcion);
      if (this.file) {
        formData.append("imagen", this.file);
      }
      formData.append("estado", this.form.estado);
      this.cargando = true; 
      try {
        if (this.currentCategoriaId) {
          formData.append("_method", "PUT");
          await CategoriaService.updateCategoria(this.currentCategoriaId, formData);
          this.$swal({
            icon: "success",
            title: "Categoría Actualizada",
            text: "La categoría ha sido actualizada exitosamente.",
          }).then(() => {
            this.fetchCategorias();
                        location.reload();
                        this.closeModal();
                    });
        } else {
          await CategoriaService.createCategoria(formData);
          this.$swal({
            icon: "success",
            title: "Categoría Registrada",
            text: "La categoría ha sido registrada exitosamente.",
          }).then(() => {
            this.fetchCategorias();
                        location.reload();
                        this.closeModal();
                    });
        }
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors;
          console.error("Errores en la validación de campos:", this.formErrors);
          this.$swal({
            icon: "error",
            title: "Error en los campos",
            text: "Por favor, revisa los errores en los campos y vuelve a intentarlo.",
          });
        } else {
          console.error("Error guardando la categoria:", error);
          this.$swal({
            icon: "error",
            title: "Error inesperado",
            text: "Hubo un error al guardar la categoria.",
          });
        }
      }finally {
            this.cargando = false; // Finaliza el estado de carga
        }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    nuevoItem() {
      this.modalTitle = "Nueva Categoría";
      this.currentCategoriaId = null;
      this.form = {
        nombre: "",
        imagen: "",
        descripcion: "",
        estado: 1,
      };
      this.showModal();
    },
    showModal() {
      const modal = document.getElementById("principalModal");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.setAttribute("aria-modal", "true");
      modal.removeAttribute("aria-hidden");
    },
    closeModal() {
      const modal = document.getElementById("principalModal");
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
      modal.removeAttribute("aria-modal");
      this.formErrors = {};
    },
  },
};
</script>

<style scoped>
/* Ajustes de la tabla para pantallas pequeñas */
.table {
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .table {
    font-size: 0.8rem;
  }
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 95%;
  }
}
</style>
