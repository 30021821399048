<template>
    <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between pb-3">
            <h5 class="mb-0 text-primary">Materiales</h5>
            <Preloader :visible="cargando" />
            <div class="d-flex align-items-center gap-2">
            <button 
                class="btn btn-sm btn-primary d-flex align-items-center gap-1"
                @click="nuevoItem()">
                <i class="fas fa-plus"></i>
                <span>Nuevo</span>
            </button>
            <button 
                class="btn btn-sm btn-outline-primary d-flex align-items-center gap-1" 
                @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
                <span>Regresar al Modulo</span>
            </button>
        </div>
    </div>
    <div class="table-responsive p-4">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th class="text-secondary opacity-7">ID</th>
                            <th class="text-start text-secondary opacity-7">Nombre</th>
                            <th class="text-secondary opacity-7">Tipo</th>
                            <th class="text-center text-secondary opacity-7">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="material in materiales" :key="material.id">
                            <td>{{material.id}}</td>
                            <td class="text-start">
                                <!-- Manejo condicional según el tipo de material -->
                                <span v-if="material.tipo === 'Archivo'">
                                    <a :href="getRutaCompleta(material.ruta)" target="_blank">{{ material.nombre }}</a>
                                </span>
                                <span v-else-if="material.tipo === 'Enlace'">
                                    <a :href="material.texto" target="_blank">{{ material.nombre }}</a>
                                </span>
                                <span v-else-if="material.tipo === 'Texto'" @click="mostrarTexto(material)" 
                                class="text-primary cursor-pointer">
                                {{ material.nombre }}
                                </span>
                            </td>
                            <td >{{ material.tipo }}</td>
                            <td>
                                <button @click="editarMaterial(material)" class="badge badge-sm bg-gradient-warning">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </button>
                                <button @click="desactivarMaterial(material.id)" class="badge badge-sm bg-gradient-danger">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal para crear/editar material -->
        <div class="modal fade" 
        id="principalModal"
        tabindex="-1"
        aria-labelledby="principalModalLabel"
        aria-hidden="true">     
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="principalModalLabel">
                            {{ modalTitle }}
                        </h5>
                        <button type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                @click="closeModal">
                        </button>
                    </div>
                    <!-- modal con el formulario -->
                    <div class="modal-body">
                        <form id="materialForm">
                            <div class="mb-3">
                                <label for="nombre" class="form-label">Nombre</label>
                                <input type="text" 
                                class="form-control" 
                                id="nombre" 
                                v-model="form.nombre" />
                                <div class="text-danger">{{ formErrors.nombre }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="tipo" class="form-label">Tipo de Material</label>
                                <select class="form-select" 
                                id="tipo" 
                                v-model="form.tipo">
                                <option disabled value="">Seleccione un tipo</option>
                                <option value="Archivo">Archivo</option>
                                <option value="Enlace">Enlace</option>
                                <option value="Texto">Texto</option>
                            </select>
                            <div class="text-danger">{{ formErrors.tipo }}</div>
                        </div>
                            
                            <!-- Campos condicionales según el tipo -->
                            <!-- Dentro del modal de edición/creación -->
                            <div class="mb-3" v-if="form.tipo === 'Archivo'">
                                <label for="ruta" class="form-label">Archivo</label>
                                <!-- Mostrar el nombre del archivo actual si existe -->
                                <div v-if="form.ruta && !file" class="mb-2">
                                    <span>Archivo actual: {{ form.ruta.split('/').pop() }}</span>
                                </div>
                                <input type="file" class="form-control" @change="handleFileUpload" />
                                <small class="form-text text-muted" v-if="currentMaterialId">Si no selecciona un nuevo archivo, se mantendrá el archivo actual.</small><div class="text-danger">{{ formErrors.ruta }}
                                </div>
                            </div>
                            <div class="mb-3" v-if="form.tipo === 'Enlace'">
                            <label for="ruta" class="form-label">Enlace</label>
                            <input type="url" 
                                class="form-control" 
                                id="ruta" 
                                v-model="form.texto" 
                                placeholder="https://ejemplo.com" />
                            <div class="text-danger">{{ formErrors.texto }}</div>
                        </div>
                        <div class="mb-3" v-if="form.tipo === 'Texto'">
                            <label for="texto" class="form-label">Texto</label>
                            <Editor v-model="form.texto" editorStyle="height:320px"/>
                            <div class="text-danger">{{ formErrors.texto }}</div>
                        </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal" 
                                @click="closeModal">
                            Cerrar
                        </button>
                        <button type="button" 
                                class="btn btn-primary" 
                                @click="guardarMaterial">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal para mostrar contenido de texto -->
        <div class="modal fade"
        id="textoModal"
        tabindex="-1"
        aria-labelledby="textoModalLabel"
        aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="textoModalLabel">
                            {{ materialSeleccionado?.nombre }}
                        </h5>
                        <button type="button"
                                class="btn-close"
                                @click="cerrarModalTexto"
                                aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="content" v-html="materialSeleccionado?.texto"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-secondary"
                                @click="cerrarModalTexto">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import Preloader from "../components/Preloader.vue";
import MaterialService from "../../services/MaterialService";
import Editor from 'primevue/editor';

export default {
    name: "Materiales",
    components: {
        Preloader,
        Editor
    },
    data() {
        return {
            materiales: [],
            cargando: false,
            id_leccion: this.$route.params.id_leccion,
            form: {
                nombre: "",
                tipo: "",
                ruta: "",
                texto: "",
                estado: 1,
                id_leccion: this.$route.params.id_leccion,
            },
            formErrors: {},
            modalTitle: "",
            currentMaterialId: null,
            materialSeleccionado: null,
            file: null,
            rutaActual: null,
        };
    },
    created() {
        this.fetchMateriales();
    },
    methods: {
        async fetchMateriales() {
            try {
                this.cargando = true;
                const response = await MaterialService.getMaterialesPorCurso(this.id_leccion);
                this.materiales = response.data.data;
            } catch (error) {
                console.error("Error fetching:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar los materiales.",
                });
            } finally {
                this.cargando = false;
            }
        },

        getRutaCompleta(ruta) {
            return `${process.env.VUE_APP_API_URL}/storage/${ruta}`;
        },
        async guardarMaterial() {
            this.formErrors = {};
            // Crear un objeto FormData para enviar al backend
            const formData = new FormData();
            formData.append("nombre", this.form.nombre);
            formData.append("tipo", this.form.tipo);
            formData.append("estado", this.form.estado);
            formData.append("id_leccion", this.form.id_leccion);
            // Manejar los diferentes tipos de contenido
            if (this.form.tipo === 'Archivo') {
                if (this.file) {
                    formData.append("ruta", this.file);
                } else if (this.currentMaterialId && this.form.ruta) {
                    formData.append("ruta_actual", this.form.ruta);
                }
            } else if (this.form.tipo === 'Enlace' || this.form.tipo === 'Texto') {
                formData.append("texto", this.form.texto);
            }
            try {
                let response;
                if (this.currentMaterialId) {
                    // Actualizar material existente
                    formData.append("_method", "PUT");
                    response = await MaterialService.updateMaterial(this.currentMaterialId, formData);
                    // Verificar la respuesta del backend al actualizar
                    console.log("Respuesta de actualización:", response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Material Actualizado",
                        text: "El material ha sido actualizado exitosamente.",
                    }).then(() => {
                        this.fetchMateriales();
                        location.reload();
                        this.closeModal();
                    });
                } else {
                    // Crear nuevo material
                    response = await MaterialService.createMaterial(formData);
                    // Verificar la respuesta del backend al crear
                    console.log("Respuesta de creación:", response.data);
                    this.$swal({
                        icon: "success",
                        title: "Material Registrado",
                        text: "El material ha sido registrado exitosamente.",
                    }).then(() => {
                        this.fetchMateriales();
                        location.reload();
                        this.closeModal();
                    });
                }
            } catch (error) {
                console.error("Error en la operación:", error);
                if (error.response && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                } else {
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al procesar el material. Por favor, intente nuevamente.",
                    });
                }
            }
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },

        async editarMaterial(material) {
    // Define la URL completa para el archivo si el material es de tipo 'Archivo'
    const rutaArchivo = process.env.VUE_APP_API_URL + material.ruta;

    // Cambia el título del modal a "Editar Material"
    this.modalTitle = "Editar Material";
    
    // Asigna el ID del material actual para identificarlo durante la edición
    this.currentMaterialId = material.id;

    // Configura los datos actuales en el formulario según el tipo de material
    this.form = {
        nombre: material.nombre,
        tipo: material.tipo,
        ruta: material.tipo === 'Archivo' ? rutaArchivo : "",   // Solo muestra ruta si es un archivo
        enlace: material.tipo === 'Enlace' ? material.enlace : "",  // Campo para enlaces si el tipo es 'Enlace'
        texto: material.tipo === 'Texto' ? material.texto : "", // Campo de texto si el tipo es 'Texto'
        estado: material.estado,
        id_leccion: material.id_leccion,
    };

    // Muestra la ruta actual si es tipo 'Archivo', o asigna null para otros tipos
    this.rutaActual = material.tipo === 'Archivo' ? rutaArchivo : null;

    // Muestra el modal de edición
    this.showModal();
},

        async desactivarMaterial(id) {
            try {
                await MaterialService.desactivarMaterial(id);
                this.$swal({
                    icon: "success",
                    title: "Material Desactivado",
                    text: "El material ha sido desactivado exitosamente.",
                }).then(() => {
                    this.fetchMateriales();
                });
            } catch (error) {
                console.error("Error desactivando el material:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al desactivar el material.",
                });
            }
        },

        showModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.add("show");
            modal.style.display = "block";
            modal.setAttribute("aria-modal", "true");
            modal.removeAttribute("aria-hidden");
            document.body.classList.add("modal-open");
        },

        closeModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.remove("show");
            modal.style.display = "none";
            modal.setAttribute("aria-hidden", "true");
            modal.removeAttribute("aria-modal");
            document.body.classList.remove("modal-open");
            this.formErrors = {};
        },

        nuevoItem() {
            this.modalTitle = "Nuevo Material";
            this.currentMaterialId = null;
            this.showModal();
        },

        mostrarTexto(material) {
            this.materialSeleccionado = material;
            const modal = document.getElementById("textoModal");
            modal.classList.add("show");
            modal.style.display = "block";
            modal.setAttribute("aria-modal", "true");
            modal.removeAttribute("aria-hidden");
            document.body.classList.add("modal-open");
        },

        cerrarModalTexto() {
            const modal = document.getElementById("textoModal");
            modal.classList.remove("show");
            modal.style.display = "none";
            modal.setAttribute("aria-hidden", "true");
            modal.removeAttribute("aria-modal");
            document.body.classList.remove("modal-open");
            this.materialSeleccionado = null;
        },
    },
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
.cursor-pointer:hover {
    text-decoration: underline;
}

.ck-editor__editable_inline {
    min-height:200px;
  }
  
  .ql-editor {
    background-color: white !important; /* Fondo blanco para el editor */
    color: black !important; /* Texto negro */
  }
</style>