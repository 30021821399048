<template>
    <div class="card">
        <div class="card-header">
            <h4 class="mb-0 text-primary">Clientes</h4>
            <Preloader :visible="cargando" />
        </div>
        <!-- Tablas -->
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-4">
                <table class="table table-bordered table-striped mb-0" ref="itemtabla">
                    <thead>
                        <tr class="text-uppercase">
                            <th class="text-start px-3 py-2">Nombre</th>
                            <th class="text-start px-3 py-2">Correo</th>
                            <th class="text-center px-3 py-2">Teléfono</th>
                            <th class="text-center px-3 py-2">Fecha de Registro</th>
                            <th class="text-center px-3 py-2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="clientes in clientes" :key="clientes.id">
                            <td class="text-start">{{ clientes.nombres }}</td>
                            <td class="text-start">{{ clientes.correo }}</td>
                            <td class="text-center">{{ clientes.telefono }}</td>
                            <td class="text-center">{{ clientes.fecha_registro }}</td>
                            <td class="text-center">
                                <button @click="verClientes(clientes)" class="badge badge-sm bg-gradient-success">
                                    <i class="far fa-eye" aria-hidden="true"></i>
                                </button>
                                <button @click="editarClientes(clientes)" class="badge badge-sm bg-gradient-warning">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <!-- Modal para crear o editar una categoría -->
        <div
        class="modal fade"
        id="principalModal"
        tabindex="-1"
        aria-labelledby="principalModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- Cabecera del modal que muestra el título dinámico -->
                <div class="modal-header">
                    <h5 class="modal-title" id="principalModalLabel">
                        {{ modalTitle }}
                    </h5>
                    <!-- Botón para cerrar el modal -->
                    <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="closeModal"></button>
                </div>
                <!-- Cuerpo del modal con el formulario para nombre, descripción e imagen -->
                <div class="modal-body">
                <form >
                    <div class="mb-3">    
                        <div class="mb-3">
                            <label for="logo" class="form-label"> Logo</label>                                             
                            <input type="file" class="form-control" @change="handleFileUpload_logo"/>
                                <div class="text-danger">{{ formErrors.logo }}</div>
                                <div v-if="form.logo">
                                    <img :src="form.logo" alt="Imagen del cliente" width="100" />
                                </div>                           
                            </div>                   
                            <div class="mb-3">
                                <label for="nombres" class="form-label">Nombre del cliente</label>
                                <input type="text" class="form-control" id="nombres" v-model="form.nombres"/>
                                <!-- Mensaje de error para el campo 'nombre' -->
                                <div class="text-danger">{{ formErrors.nombre }}</div> </div>
                                <div class="mb-3">
                                    <label for="correo" class="form-label">Correo</label>
                                    <input type="email" class="form-control" id="correo" v-model="form.correo" />
                                    <div class="text-danger">{{ formErrors.correo }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="correo" class="form-label">Telefono</label>
                                    <input type="text" class="form-control" id="telefono" v-model="form.telefono" />
                                    <div class="text-danger">{{ formErrors.telefono }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="fecha_registro" class="form-label">Fecha de Registro</label>
                                    <input type="date" class="form-control" id="fecha_registro" v-model="form.fecha_registro" />
                                    <div class="text-danger">{{ formErrors.fecha_registro }}</div>
                                </div>
                                
                                <div class="mb-3">
                                    <label for="numero_documento" class="form-label">Número de Documento</label>
                                    <input type="text" class="form-control" id="numero_documento" v-model="form.numero_documento" />
                                    <div class="text-danger">{{ formErrors.numero_documento }}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="direccion" class="form-label">Dirección</label>
                                    <input type="text" class="form-control" id="direccion" v-model="form.direccion" />
                                    <div class="text-danger">{{ formErrors.direccion }}</div>
                                </div>
                                <div>  
                                    <h6>Botón 01</h6>                       
                                    <div class="mb-3">                              
                                        <input type="text" class="form-control" v-model="form.boton_1" placeholder="Texto del Botón 1" />
                                        <div class="text-danger">{{ formErrors.boton_1 }}</div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.link_boton_1" placeholder="Ingrese el Link del Botón 1" />
                                        <div class="text-danger">{{ formErrors.link_boton_1 }}</div>
                                    </div>
                                    <hr>
                                    <h6>Botón 02</h6>       
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.boton_2" placeholder="Texto del Botón 2" />
                                        <div class="text-danger">{{ formErrors.boton_2 }}</div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.link_boton_2" placeholder="Ingrese el Link del Botón 2" />
                                        <div class="text-danger">{{ formErrors.link_boton_2 }}</div>
                                    </div>
                                    <hr>
                                    <h6>Botón 03</h6>      
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.boton_3" placeholder="Texto del Botón 3" />
                                        <div class="text-danger">{{ formErrors.boton_3 }}</div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.link_boton_3" placeholder="Ingrese el Link del Botón 3" />
                                        <div class="text-danger">{{ formErrors.link_boton_3 }}</div>
                                    </div>
                                </div>               
                                <div class="mb-3">
                                    <label for="link_banner_uno" class="form-label"> Banner Uno</label>  
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.link_banner_uno" placeholder="TLink Banner 1" />
                                        <div class="text-danger">{{ formErrors.link_banner_uno }}</div>
                                    </div>                       
                                    <input type="file" class="form-control" @change="handleFileUpload_uno"/>
                                    <div class="text-danger">{{ formErrors.banner_uno }}</div>
                                    
                                    <div v-if="form.banner_uno">
                                        <img :src="form.banner_uno" alt="Imagen del baner uno" width="100" />
                                    </div>                           
                                </div>
                                <div class="mb-3">
                                    <label for="link_banner_dos" class="form-label"> Banner dos</label>   
                                    <div class="mb-3">
                                        <input type="text" class="form-control" v-model="form.link_banner_dos" placeholder="Link de Banner 2" />
                                <div class="text-danger">{{ formErrors.link_banner_dos }}</div>
                            </div>                         
                            <input type="file" class="form-control" @change="handleFileUpload_dos"/>
                            <div class="text-danger">{{ formErrors.banner_dos }}</div>
                            <div v-if="form.banner_dos">
                                <img :src="form.banner_dos" alt="Imagen del baner dos" width="100" />
                            </div>                           
                        </div>                        
                    </div>
                </form>
            </div>
            <!-- Pie del modal con los botones para cerrar y guardar la categoría -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
                <!-- Botón para guardar la categoría -->
                <button
                type="button"
                class="btn btn-primary"
                @click="guardarClientes"
                >
                Guardar
                </button>
            </div>
            </div>
        </div>
    </div>
    </div>
    </template>

    <script>
    import Clienteservice from "../../services/Clienteservice";
    import Preloader from "../components/Preloader.vue";
    import $ from 'jquery';


    export default {
        data() {
            return {
                clientes: [],
                cargando: false,
                form: {
                    nombres: '',
                    correo: '',
                    fecha_registro: '',
                    numero_documento: '',
                    direccion:'',
                    telefono:'',
                    logo:'',
                    boton_1:'',
                    boton_2:'',
                    boton_3:'',
                    link_boton_1: '',
                    link_boton_2: '',
                    link_boton_3: '',
                    banner_uno:'',
                    banner_dos:'',
                    link_banner_uno: '',
                    link_banner_dos: '',                
                },
                currentItemId: null,
                isModalVisible: false,
                modalTitle: "",
                formErrors: {},
                file_uno:'',
                file_dos:'',
                file_logo:'',

            };
        },
        created() {
        this.fetchClientes();// Llama a la función para cargar las categorías cuando el componente se monta
        },
        components: {
            Preloader

        },
        methods: {
            handleFileUpload_logo(event) {
                this.file_logo = event.target.files[0];
            },
            handleFileUpload_uno(event) {
                this.file_uno = event.target.files[0];
            },
            handleFileUpload_dos(event) {
                this.file_dos = event.target.files[0];
            },
            async fetchClientes() {
                try {
                    this.cargando = true;
                    const response = await Clienteservice.getClientesActivos();
                    this.clientes = response.data.data;
                    this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [[0, 'desc']],
                            pageLength: 20
                        });
                    });
                } catch (error) {
                    console.error("Error fetching clientes:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los clientes.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            verClientes(clientes) {
            const logoURL = process.env.VUE_APP_API_URL +'/storage/'+ clientes.logo;
            const banner_uno = process.env.VUE_APP_API_URL +'/storage/'+ clientes.banner_uno;
            const banner_dos = process.env.VUE_APP_API_URL +'/storage/'+ clientes.banner_dos;
            const detalles =`<div style="display: flex; flex-wrap: wrap;">
                <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                    <p><strong>Nombre:</strong> ${clientes.nombres}</p>
                    <p><strong>Ciudad:</strong> ${clientes.ciudad}</p>
                    <p><strong>Direccion:</strong> ${clientes.direccion}</p>                 
                    <p><strong>Logo:</strong> <img src="${logoURL}" alt="logo" width="100"></p>                                    </div>
                    <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                    <p><strong>Telefono:</strong> ${clientes.telefono}</p>
                    <p><strong>Correo:</strong> ${clientes.correo}</p>
                    <p><strong>Numero de documento:</strong> ${clientes.numero_documento}</p>
                </div>
                <div style="flex: 1; min-width: 100%; text-align: left; padding-left: 10px;">
                    <hr>
                    <p><strong>Texto Boton 01  :</strong>  ${clientes.boton_1}</p>                   
                    <p><strong>Enlace : </strong> ${clientes.link_boton_1}</p>
                    <hr>
                    <p><strong>Texto Boton 02  :</strong>  ${clientes.boton_2}</p>                   
                    <p><strong>Enlace : </strong> ${clientes.link_boton_2}</p>
                    <hr>
                    <p><strong>Texto Boton 03  :</strong>  ${clientes.boton_3}</p>                   
                    <p><strong>Enlace : </strong> ${clientes.link_boton_3}</p>
                </div>
                    <div style="flex: 1; min-width: 100%; text-align: left; padding-left: 10px;">
                    <hr>
                    <p><strong>Enlace Banner 01 :</strong>  ${clientes.link_banner_uno}</p>
                    <p><img src="${banner_uno}" alt="banner_uno" width="100"></p>
                    <hr>
                    <p><strong>Enlace Banner 02 : </strong> ${clientes.link_banner_dos}</p>
                    <p><img src="${banner_dos}" alt="banner_dos" width="100"></p>
                </div>
                </div>`;
                        this.$swal({
                            icon: "info",
                            title: "Detalles del cliente",
                            html: detalles,
                            customClass: {
                                popup: "swal-wide",
                            },
                            width: "50%",
                        });
                    },

            async guardarClientes() {
            const formData = new FormData();
            formData.append("nombres", this.form.nombres);
            formData.append("correo", this.form.correo);
            formData.append("numero_documento", this.form.numero_documento); 
            formData.append("link_boton_1", this.form.link_boton_1);
            formData.append("link_boton_2", this.form.link_boton_2);
            formData.append("link_boton_3", this.form.link_boton_3);
            formData.append("link_banner_uno", this.form.link_banner_uno);
            formData.append("link_banner_dos", this.form.link_banner_dos);
            formData.append("telefono", this.form.telefono);
            formData.append("boton_1", this.form.boton_1);
            formData.append("boton_2", this.form.boton_2);
            formData.append("boton_3", this.form.boton_3);
            formData.append("direccion", this.form.direccion);
            if (this.file_uno) {
                formData.append("banner_uno", this.file_uno);
            }
            if (this.file_dos) {
                formData.append("banner_dos", this.file_dos);
            }
            if (this.file_logo) {
                formData.append("logo", this.file_logo);
            }
            this.cargando = true; 
            try {
                formData.append("_method", "PUT");
                await Clienteservice.updateCliente(
                    this.currentItemId,
                    formData
                );
                this.$swal({
                    icon: "success",
                    title: "Cliente Actualizado",
                    text: "El cliente ha sido actualizado exitosamente.",
                }).then(() => {
                        location.reload();
                        this.closeModal();
                    });
            } catch (error) {
                if (error.response && error.response.data.errors) {
                this.formErrors = error.response.data.errors;
                } else {
                console.error("Error guardando:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al guardar.",
                });
                }
            }finally {
            this.cargando = false; // Finaliza el estado de carga
        }
            },
            editarClientes(clientes) {
                const imagen_log = process.env.VUE_APP_API_URL +'/storage/'+ clientes.logo;
                const banner_uno = process.env.VUE_APP_API_URL +'/storage/'+ clientes.banner_uno;
                const banner_dos = process.env.VUE_APP_API_URL +'/storage/'+ clientes.banner_dos;
                this.modalTitle = "Editar Cliente";
                this.currentItemId = clientes.id; 
                this.form = {
                    nombres: clientes.nombres,
                    tipo_cliente: clientes.tipo_cliente,
                    estado: clientes.estado,
                    numero_documento: clientes.numero_documento,
                    direccion: clientes.direccion,    
                    telefono: clientes.telefono,
                    correo: clientes.correo,
                    logo: imagen_log,
                    banner_dos: banner_dos,
                    banner_uno: banner_uno,
                    fecha_registro: clientes.fecha_registro,
                    link_boton_1: clientes.link_boton_1 || '',
                    link_boton_2: clientes.link_boton_2 || '',
                    link_boton_3: clientes.link_boton_3 || '',
                    link_banner_uno: clientes.link_banner_uno || '',
                    link_banner_dos: clientes.link_banner_dos || '',
                    boton_1: clientes.boton_1 || '',
                    boton_2: clientes.boton_2 || '',
                    boton_3: clientes.boton_3 || ''
                };
                this.showModal();
            },
            
            showModal() {
                    const modal = document.getElementById("principalModal");
                    modal.classList.add("show");
                    modal.style.display = "block";
                    modal.setAttribute("aria-modal", "true");
                    modal.removeAttribute("aria-hidden");
                },
            closeModal() {
                        const modal = document.getElementById("principalModal");
                        modal.classList.remove("show");
                        modal.style.display = "none";
                        modal.setAttribute("aria-hidden", "true");
                        modal.removeAttribute("aria-modal");
                        this.formErrors = {};
            },
            
        },
            mounted() {
                this.fetchClientes(); // Llamada al método para obtener datos al cargar el componente
            }
            };
    </script>

    <style>

    </style>