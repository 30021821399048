<template>
  <div>
    <!-- Contenedor principal -->
    <div v-if="!mostrarDetalle" class="card shadow-sm">
      <div class="card-header pb-0 d-flex align-items-center justify-content-between">
        <div>
          <h6 v-if="nombreCurso" class="text-primary">Curso: {{ nombreCurso }}</h6>
          <h6 v-if="nombreModulo" class="text-success">Módulo: {{ nombreModulo }}</h6>
        </div>
        <h6>Estudiantes</h6>
        <Preloader :visible="cargando" />
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-4">
          <table class="table table-bordered table-striped mb-0">
            <thead class="thead-light">
              <tr>
                <th class="text-center px-3 py-2">ID</th>
                <th class="text-start px-3 py-2">Nombre</th>
                <th class="text-center px-3 py-2">Nota</th>
                <th class="text-center px-3 py-2">Intentos</th>
                <th class="text-center px-3 py-2">Estado</th>
                <th class="text-center px-3 py-2">Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(detalle, index) in detalle_evaluaciones" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-start">{{ detalle.alumno }}</td>
                <td class="text-center">
                  <span v-if="detalle.condicion">{{ detalle.nota }}</span>
                  <span v-else class="text-danger">Sin Nota</span>
                </td>
                <td class="text-center">{{ detalle.intentos }}</td>
                <td class="text-center">
                  <span v-if="detalle.condicion == 1" class="text-success p-2">Calificado</span>
                  <span v-else class="text-danger p-2">Sin calificar</span>
                </td>
                <td class="text-start">
                  <button class="btn btn-success p-2" @click="abrirDetalleRespuestas(detalle)">
                    Corregir
                  </button>
                  <button class="btn btn-primary p-2 ms-2" @click="generarCertificado(detalle)">
                    Generar Certificado
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Contenedor con las respuestas -->
    <div v-if="mostrarDetalle" class="card shadow-lg border-0">
      <!-- Encabezado del detalle -->
      <div class="card-header d-flex justify-content-between align-items-center" style="background-color: #f4f6f9; border-radius: 10px;">  
        <!-- Información del alumno y evaluación -->
        <div class="text-start card-header-info">
          <h4 class="mb-0 text-primary">Detalle de evaluación</h4>
          <p class="mb-0"><strong>Alumno(a):</strong> <span class="text-primary">{{ detalle.alumno }}</span></p>
          <p class="text-start"><strong>Evaluación:</strong> <span class="text-success">{{ evaluacion.nombre }}</span></p>
        </div>
  
        <Preloader :visible="cargando" />
      </div>
  
      <!-- Cuerpo del card -->
      <div class="card-body">
        <p class="text-muted" style="font-size: 14px; margin-bottom: 20px;">
          Las respuestas del alumno y el valor de la pregunta no pueden ser modificados. Modifique solo el valor para generar la calificación final.
        </p>
  
        <!-- Contenedor de respuestas -->
        <div v-if="respuestas.length > 0">
          <div v-for="(respuesta, index) in respuestas" :key="index" class="card mb-4 shadow-sm border-0">
            <div class="card-body">
              <!-- Pregunta y respuesta -->
              <div class="row mb-3">
                <div class="col-md-13">
                  <h6 class="card-title text-primary">Pregunta {{ index + 1 }}</h6>
                  <p class="card-text">{{ respuesta.pregunta?.pregunta || 'Sin pregunta' }}</p>
                  <p class="card-text"><strong>Respuesta:</strong> {{ respuesta.respuesta || 'Sin respuesta' }}</p>
                </div>
              </div>
  
              <!-- Valor de la pregunta debajo de la respuesta -->
              <div class="form-group mt-3 col-md-2">
                <label :for="'valor-pregunta-' + index" class="form-label">Valor de la Pregunta</label>
                <input 
                  type="number" 
                  :id="'valor-pregunta-' + index"
                  class="form-control" 
                  v-model="respuesta.pregunta.valor" 
                  min="0" 
                  max="100"
                />
              </div>
            </div>
          </div>
  
          <!-- Controles y calificación -->
          <div class="d-flex justify-content-between align-items-center mt-4">
            <!-- Calificación -->
            <div class="col-md-1">
              <div class="form-group">
                <label for="calificacion" class="form-label">Calificación</label>
                <input 
                  type="number" 
                  class="form-control" 
                  id="calificacion" 
                  v-model="calificacion" 
                  readonly
                />
              </div>
            </div>
  
            <!-- Botones -->
            <div class="d-flex">
              <button class="btn btn-secondary me-3" @click="cerrarDetalleRespuestas">
                Cerrar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="calificarDetalle"
              >
                Calificar
              </button>
            </div>
          </div>
        </div>
  
        <!-- Mensaje si no hay respuestas -->
        <div v-else class="text-muted text-center">
          No hay respuestas disponibles para este estudiante.
        </div>
      </div>

    </div>
    </div>
</template>



<script>
import Preloader from "../components/Preloader.vue";
import RespuestaService from "../../services/RespuestaService"; // Servicio para las respuestas
import DetalleEvaluacionService from "../../services/DetalleEvaluacionService"; // Servicio para detalle de evaluaciones
import Swal from 'sweetalert2';
import EvaluacionService from "../../services/EvaluacionService";
import ModuloService from "../../services/ModuloService";
import CursoService from "../../services/CursoService";
import CertificadoService from "../../services/CertificadoService";

export default {
  name: "DetalleEvaluacion",
  data() {
    return {
      mostrarDetalle: false, // Controla la visibilidad del contenedor de respuestas
      detalle_evaluaciones: [], 
      respuestas: [], 
      certificado: [], 
      id_idevaluacion: this.$route.params.idevaluacion, 
      cargando: false, 
      modalTitle: "", 
      valorTotal: 0,
      especialidad:{},
      detalle: {},
      evaluacion:{},
      notafinal: 0,
      showCertificadoModal: false, 
      formcertificados: {
        id_usuario: null, 
        estado: 1, 
        tipo_certificado: '',
        nota: 0, 
        horas: null,
        fecha_emision: '',
        fecha_inicio: '',
        fecha_fin: '',
        codigo_certificado: '', 
      },
      nombreCurso: '', 
      nombreModulo: '', 
    };
  },
  
  created() {
  if (!this.id_idevaluacion) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "ID de evaluación no encontrado.",
    });
    return;
  }

  // Ejecuta ambas funciones dentro de la misma clave 'created'
  this.fetchDetalleEvaluaciones();
  this.fetchEvaluacion();
},

  
  components: {
    Preloader,
  },
  computed:{
    calificacion() {
    // Primero, verifica que respuestas exista
    if (!this.respuestas) {
      console.warn('No hay respuestas disponibles');
      return 0;
    }

    // Si respuestas es un array, procede
    if (Array.isArray(this.respuestas)) {
      return this.respuestas.reduce((sum, respuesta) => {
        // Verifica que respuesta y respuesta.pregunta existan
        if (respuesta && respuesta.pregunta && respuesta.pregunta.valor !== undefined && respuesta.pregunta.valor !== null) {
          // Convierte a número, maneja casos especiales
          const valor = Number(respuesta.pregunta.valor);
          return sum + (isNaN(valor) ? 0 : valor);
        }
        return sum;
      }, 0);
    }

    console.warn('respuestas no es un array');
    return 0;
  }
},
  watch: {
    calificacion(newVal) {
    console.log('Nueva calificación:', newVal);
    // Actualiza la variable notafinal de forma segura
    this.detalle.nota = newVal || 0;
  },
},
methods: {
  async fetchEvaluacion() {
    this.cargando=true;
    try {
      const respuesta = await EvaluacionService.getEvaluacion(this.id_idevaluacion);
      this.evaluacion = respuesta.data.data;
        if (this.evaluacion) {
          if (this.evaluacion.tipo_evaluacion == 'modulo') {
            await this.datosModulo(this.evaluacion.id_modulo);
          }
          if (this.evaluacion.tipo_evaluacion == 'curso') {
            await this.datosCurso(this.evaluacion.id_curso);
          } 
        } else {
          console.error("Evaluación no encontrada.");
        }
      } catch (error) {
        console.error("Error al obtener evaluación:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo cargar la evaluación.",
        });
    }
    finally{
      this.cargando=false;
    }
},


async datosModulo(id) {
  const respuesta = await ModuloService.getModulo(id);
    this.especialidad = respuesta.data.data;
    this.especialidad.tipoespecialidad = 'modulo';
    this.nombreModulo = this.especialidad.nombre;
 }
,
async datosCurso(id) {
  const respuesta = await CursoService.getCurso(id);
    this.especialidad = respuesta.data.data;
    this.especialidad.tipoespecialidad = 'curso';
    this.nombreCurso = this.especialidad.nombre;
  },
  async generarCertificado(detalle) {
    this.cargando = true;
    try {
        this.modalTitle = "Generar certificado";
        let responseCertificado = null;
        if (this.evaluacion.tipo_evaluacion === 'curso') {
            responseCertificado = await CertificadoService.getCertificadoCurso(this.especialidad.id, detalle.id_alumno);
        } 
        if (this.evaluacion.tipo_evaluacion === 'modulo') {
            responseCertificado = await CertificadoService.getCertificadoModulo(this.especialidad.id, detalle.id_alumno);
        }
        const certificadoData = responseCertificado?.data?.data ?? null;
        if (certificadoData && typeof certificadoData === 'object' && Object.keys(certificadoData).length > 0) {
            const tipo = this.evaluacion.tipo_evaluacion === 'curso' ? 'curso' : 'módulo';
            const textoMensaje = `El certificado para este ${tipo} ya ha sido generado con éxito.`;
            Swal.fire({
                icon: 'warning',
                title: '¡Certificado ya generado!',
                text: textoMensaje,
            });
            return;
        }
        this.formcertificados = {
            tipo_certificado: this.especialidad.tipoespecialidad,
            id_usuario: detalle.id_alumno,
            id_modulo: this.especialidad.tipoespecialidad === 'modulo' ? this.especialidad.id : null,
            id_curso: this.especialidad.tipoespecialidad === 'curso' ? this.especialidad.id : null,
            estado: 1,
            nota: detalle.nota,
            horas: this.especialidad.horas,
            fecha_emision: '',
            fecha_inicio: this.especialidad.fecha_inicio,
            fecha_fin: this.especialidad.fecha_fin,
            codigo_certificado: '',
        };
        this.showSweetAlert();
    } catch (error) {
        console.error(error);
    } finally {
        this.cargando = false;
    }
},

showSweetAlert() {
  Swal.fire({
    title: this.modalTitle,
    html: `
      <div class="form-group" style="text-align: left;">
        <label for="nota">Nota</label>
        <input type="number" id="nota" class="swal2-input" value="${this.formcertificados.nota}" placeholder="Nota del certificado" style="text-align: left;" disabled>
      </div>
      <div class="form-group" style="text-align: left;">
        <label for="horas">Horas</label>
        <input type="number" id="horas" class="swal2-input" value="${this.formcertificados.horas}" placeholder="Horas del certificado" style="text-align: left;" disabled>
      </div>
      <div class="form-group" style="text-align: left;">
        <label for="fecha_emision">Fecha de emisión</label>
        <input type="date" id="fecha_emision" class="swal2-input" value="${this.formcertificados.fecha_emision}" style="text-align: left;">
      </div>
      <div class="form-group" style="text-align: left;">
        <label for="fecha_inicio">Fecha de inicio</label>
        <input type="date" id="fecha_inicio" class="swal2-input" value="${this.formcertificados.fecha_inicio}" style="text-align: left;">
      </div>
      <div class="form-group" style="text-align: left;">
        <label for="fecha_fin">Fecha de fin</label>
        <input type="date" id="fecha_fin" class="swal2-input" value="${this.formcertificados.fecha_fin}" style="text-align: left;">
      </div>
      <div class="form-group" style="text-align: left;">
        <label for="codigo_certificado">Código Certificado</label>
        <input type="text" id="codigo_certificado" class="swal2-input" value="${this.formcertificados.codigo_certificado}" placeholder="Código del certificado" style="text-align: left;">
      </div>
    `,
    showCancelButton: true,
    confirmButtonText: 'Guardar cambios',
    cancelButtonText: 'Cancelar',
    preConfirm: () => {
      this.formcertificados.horas = document.getElementById('horas').value;
      this.formcertificados.fecha_emision = document.getElementById('fecha_emision').value;
      this.formcertificados.fecha_inicio = document.getElementById('fecha_inicio').value;
      this.formcertificados.fecha_fin = document.getElementById('fecha_fin').value;
      this.formcertificados.codigo_certificado = document.getElementById('codigo_certificado').value;

    return this.formcertificados;
    }
  }).then((result) => {
    if (result.isConfirmed) {
      console.log('Certificado editado:', result.value);
      this.guardarEdicionCertificado(result.value);
    }
  });
},
async guardarEdicionCertificado() {
    this.loading = true;
    try {
        if (!this.formcertificados.nota || !this.formcertificados.horas) {
            throw new Error("Faltan datos obligatorios.");
        }

        const response = await CertificadoService.createCertificado(this.formcertificados);
        console.log(response.data.data);

        Swal.fire({
            icon: "success",
            title: "Éxito",
            text: "Certificado guardado correctamente.",
        });
        await this.fetchDetalleEvaluaciones(); // Refrescar datos
        this.closeModal();
        } catch (error) {
            console.error("Error guardando certificado:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un problema al guardar el certificado.",
            });
        } finally {
            this.loading = false;
        }
},


    // Cargar los estudiantes
    async fetchDetalleEvaluaciones() {
      this.cargando = true;
      try {
        const response = await DetalleEvaluacionService.getDetalledeEvaluacionporEvaluacion(
          this.$route.params.idevaluacion
        );
        this.detalle_evaluaciones = response.data.data;
      } catch (error) {
        console.error("Error al cargar los estudiantes:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron cargar los estudiantes.",
        });
      } finally {
        this.cargando = false;
      }
    },

    
    async abrirModalRespuestas(detalle) {
  this.cargando = true; // Iniciar el estado de carga
  this.modalTitle = `Respuestas de: ${detalle.alumno}`;
  this.certificado = { ...detalle }; // Crear una copia independiente
  this.detalle = { ...detalle }; // Copia para trabajar en el modal

  console.log('Nota del estudiante:', this.detalle.nota);

  try {
    const response = await RespuestaService.getRespuestasPorDetalle(detalle.id);
    this.respuestas = response.data.data;

    const total = this.respuestas.reduce((sum, r) => {
      const valor = parseInt(r.pregunta.valor) || 0; // Manejar valores no válidos
      return sum + valor;
    }, 0);

    if (!this.detalle.nota) {
      this.detalle.nota = total; // Calcular nota solo si no existe
    }

    console.log('Total de respuestas (nota):', total);
    console.log('Detalle de evaluación con nota:', this.detalle);

    this.valorTotal = total;

    this.showModal();
  } catch (error) {
    console.error("Error al cargar respuestas:", error);
    this.$swal({
      icon: "error",
      title: "Error",
      text: "No se pudieron cargar las respuestas.",
    });
  } finally {
    this.cargando = false; // Finalizar el estado de carga
  }
},
async abrirDetalleRespuestas(detalle) {
  this.cargando = true; 
  this.mostrarDetalle = true; 
  this.detalle = { ...detalle }; 
  
  // Reinicia respuestas de forma segura
  this.respuestas = [];

  try {
    const response = await RespuestaService.getRespuestasPorDetalle(detalle.id);
    
    // Validación exhaustiva de la respuesta
    if (response && 
        response.data && 
        Array.isArray(response.data.data) && 
        response.data.data.length > 0) {
      
      // Filtrar respuestas válidas
      this.respuestas = response.data.data.filter(respuesta => 
        respuesta && 
        respuesta.pregunta && 
        respuesta.pregunta.valor !== undefined &&
        respuesta.pregunta.valor !== null
      );

      // Log para diagnóstico
      console.log('Respuestas válidas:', this.respuestas);

      // Calcular valor total de forma segura
      this.valorTotal = this.respuestas.reduce((sum, respuesta) => {
        const valor = Number(respuesta.pregunta.valor);
        return sum + (isNaN(valor) ? 0 : valor);
      }, 0);

      // Asignar nota inicial si no existe
      if (!this.detalle.nota) {
        this.detalle.nota = this.calificacion;
      }

    } else {
      console.warn('No se encontraron respuestas válidas');
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: "No se encontraron respuestas para calificar.",
      });
    }
  } catch (error) {
    console.error("Error crítico al cargar respuestas:", error);
    this.respuestas = []; // Asegurar array vacío
    Swal.fire({
      icon: "error",
      title: "Error Crítico",
      text: "No se pudieron cargar las respuestas. Por favor, contacte con soporte.",
    });
  } finally {
    this.cargando = false;
  }
},
    async calificarDetalle() {
      // Validar que la calificación no supere el valor total permitido
      const nuevaNota = this.calificacion;
      if (nuevaNota > this.valorTotal) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `La nota máxima permitida es: ${this.valorTotal}`,
        });
        return;
      }

      this.detalle.nota = nuevaNota; // Actualizar la nota calculada
      this.detalle.condicion = 1; // Marcar como calificado

      try {
        await DetalleEvaluacionService.updateDetalle(this.detalle.id, this.detalle);

        this.$swal({
      icon: "success",
      title: "Calificado",
      text: "Las respuestas han sido calificadas correctamente.",
    });

        // Refrescar datos de la tabla principal
        this.fetchDetalleEvaluaciones(); // Refrescar datos
        this.mostrarDetalle = false; // Volver a la vista principal
      } catch (error) {
        console.error("Error al guardar la calificación:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo guardar la calificación.",
        });
      }
    },

    cerrarDetalleRespuestas() {
      // Volver a la vista principal
      this.mostrarDetalle = false;
    },
  },
    // Descargar certificado
    descargarCertificado(certificado) {
      const url = `https://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
      window.open(url, "_blank");
    },
    // Mostrar el modal
    showModal() {
      const modal = document.getElementById("principalModal");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.setAttribute("aria-modal", "true");
      modal.removeAttribute("aria-hidden");
    },
    // Cerrar el modal
    closeModal() {
    const modal = document.getElementById("principalModal");
    modal.classList.remove("show");
    modal.style.display = "none";
    modal.setAttribute("aria-hidden", "true");
    modal.removeAttribute("aria-modal");
},
  
};
</script>

<style>
/* Hacer que el contenido del modal sea responsivo */
.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
.table-responsive {
  overflow-x: auto;
}
textarea {
  resize: vertical;
}


</style>
