<template>
  <div class="card p-4 shadow-lg border-0">
    <div class="card-header bg-primary text-white rounded">
      <h4 class="mb-0 text-white">Enviar Notificaciones</h4>
    </div>
    <div class="card-body px-4 pt-3 pb-2">
      <form>
        <div class="row gy-3">
          <!-- Selección de Tipo -->
          <div class="col-md-4">
            <label for="tipo_pregunta" class="form-label fw-bold">Tipo</label>
            <div class="d-flex">
              <div class="form-check me-3">
                <input
                  class="form-check-input"
                  type="radio"
                  id="tipo_destinatario_alumno"
                  v-model="tipo_destinatario"
                  value="alumno"
                />
                <label class="form-check-label" for="tipo_destinatario_alumno">Estudiante</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="tipo_destinatario_curso"
                  v-model="tipo_destinatario"
                  value="curso"
                />
                <label class="form-check-label" for="tipo_destinatario_curso">Curso</label>
              </div>
            </div>
          </div>

          <!-- Selección de Alumnos -->
          <div class="col-md-4" v-show="tipo_destinatario === 'alumno'">
            <label for="alumno" class="form-label fw-bold">Alumnos</label>
            <v-select
              :options="alumnos"
              label="nombres_completos"
              :reduce="(alumno) => alumno.id"
              v-model="form.id_alumno"
              multiple
              placeholder="Seleccione alumnos"
            />
          </div>

          <!-- Selección de Cursos -->
          <div class="col-md-4" v-show="tipo_destinatario === 'curso'">
            <label for="curso" class="form-label fw-bold">Cursos</label>
            <v-select
              :options="cursos"
              label="nombre"
              :reduce="(curso) => curso.id"
              v-model="curso"
              placeholder="Seleccione curso"
            />
          </div>
        </div>

        <!-- Mensaje -->
        <div class="mt-4">
          <label for="mensaje" class="form-label fw-bold">Mensaje</label>
          <Editor v-model="form.mensaje" editorStyle="height: 320px;" />
          <small class="text-danger">{{ formErrors.mensaje }}</small>
        </div>

        <!-- Botones -->
        <div class="mt-4 d-flex justify-content-end">
          <!-- <button type="button" class="btn btn-secondary me-3" @click="limpiarP">Limpiar</button> -->
          <button type="button" class="btn btn-primary" @click="guardarNotificacion">Guardar</button>
        </div>
      </form>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>
  <script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import UsuarioService from "../../services/UsuarioService";
import CursoService from "../../services/CursoService";
import NotificacionesService from "../../services/NotificacionesService";
import MatriculaService from "../../services/MatriculaService";
import Preloader from "../components/Preloader.vue";
import Editor from 'primevue/editor';


export default {
  name: "Notificaciones",
  components: {
    vSelect,
    Preloader,
    Editor
  },
  data() {
    return {
      cargando: false,
      tipo_destinatario: "",
      alumnos: [],
      cursos: [],
      curso: null,
      matriculas: [],
      usuarios: [], // Lista de usuarios disponibles
      form: {
        tipo: "Otro",
        fecha_envio: new Date().toISOString().split("T")[0],
        estado: 1,
        mensaje: "",
        id_alumno: null,
      },
      formErrors: {}, // Almacena errores del formulario
    };
  },

  mounted() {
    this.fetchAlumnos();
    this.fetchCursos();
  },
  
  methods: {
    async fetchAlumnos() {
      try {
        this.cargando = true;
        const response = await UsuarioService.getAlumnosActivos();
        this.alumnos = response.data.data;
      } catch (error) {
        console.error("Error fetching alumnos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los alumnos.",
        });
      } finally {
        this.cargando = false;
      }
    },

    async fetchCursos() {
      try {
        const response = await CursoService.getCursos();
        this.cursos = response.data.data;
      } catch (error) {
        console.error("Error fetching cursos:", error);
      }
    },

    limpiarP() {
      this.tipo_destinatario = null;

      this.form = {
        tipo: "Otro",
        fecha_envio: new Date().toISOString().split("T")[0],
        estado: 1,
        mensaje: "",
        id_alumno: null,
      };

      this.$swal({
        icon: "success",
        title: "Enviada",
        text: "Area de texo limpia",
      });
    },

    async guardarNotificacion() {
      this.cargando = true;
      try {
        if (this.tipo_destinatario === "alumno") {
          await this.llamarAlumnos();
        } else if (this.tipo_destinatario === "curso") {
          await this.llamarMatriculas();
        } else {
          console.log("No se seleccionó ningún tipo de destinatario.");
        }
      } catch (error) {
        console.error("Error al guardar notificación:", error);
      } finally {
        this.cargando = false;
      }
    },

    async llamarMatriculas() {
      try {
        this.cargando = true;
        const response = await MatriculaService.getMatriculasPorCurso(this.curso);
        this.matriculas = response.data.data;
        for (const matricula of this.matriculas) {
          this.form.id_alumno = matricula.id_usuario;
          try {
            const notificacionResponse = await NotificacionesService.createNotificaciones(this.form);
            console.log(notificacionResponse);
          } catch (notificacionError) {
            console.error(`Error al enviar la notificación al alumno ${matricula.id_usuario}:`, notificacionError);
          }
        }
        // Llama a limpiarP después de completar todas las notificaciones.
        this.limpiarP();
      } catch (error) {
        console.error("Error fetching matriculas:", error);
      } finally {
        this.cargando = false;
      }
    },

    async llamarAlumnos() {
      try {
        this.cargando = true; // Activa el indicador de carga.

        // Verifica si hay IDs en el array `id_alumno`.
        if (!this.form.id_alumno || !Array.isArray(this.form.id_alumno) || this.form.id_alumno.length === 0) {
          throw new Error("Debe seleccionar al menos un alumno.");
        }

        // Itera sobre los IDs de `id_alumno` y envía una notificación por cada uno.
        for (const id_alumno of this.form.id_alumno) {
          // Clona el formulario para evitar sobrescribir los datos.
          const notificacionData = {
            ...this.form,
            id_alumno, // Sobrescribe `id_alumno` con el ID actual.
          };

          try {
            const response = await NotificacionesService.createNotificaciones(notificacionData);
            console.log(`Notificación enviada al alumno ${id_alumno}:`, response);
          } catch (notificacionError) {
            console.error(`Error al enviar la notificación al alumno ${id_alumno}:`, notificacionError);
          }
        }

        // Llama a limpiarP después de completar todas las notificaciones.
        this.limpiarP();
      } catch (error) {
        console.error("Error al procesar notificaciones para los alumnos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: error.message || "Hubo un problema al enviar las notificaciones.",
        });
      } finally {
        this.cargando = false; // Desactiva el indicador de carga.
      }
    },
  },
};
</script>

<style scoped>
.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.text-danger {
  font-size: 0.875rem;
  color: #e3342f;
}

.ck-editor__editable_inline {
  min-height:200px;
}

.ql-editor {
  background-color: white !important; /* Fondo blanco para el editor */
  color: black !important; /* Texto negro */
}

.card {
  border-radius: 10px;
}

.card-header {
  background: #007bff;
  color: white;
  font-size: 1.25rem;
}

.btn {
  border-radius: 5px;
}

.form-label {
  font-weight: bold;
}

.v-select {
  border-radius: 5px;
}

.ql-editor {
  background: #ffffff !important;
  color: #000000 !important;
}
</style>
