<template>
    <div class="card">
        <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between pb-3">
                <h4 class="mb-0 text-primary">Cursos</h4>
                <button 
                    class="btn btn-primary d-flex align-items-center gap-2" 
                    @click="nuevoItem()">
                    <i class="fas fa-plus"></i>
                    <span>Nuevo Curso</span>
                </button>
            </div>
            <Preloader :visible="cargando" />
            <div class="importacion-container m-4 p-4 border rounded shadow-sm bg-light">
                <h3 class="text-primary mb-3">Importación Masiva de Cursos</h3>
                <p class="text-muted mb-4">Selecciona un archivo Excel para importar los cursos.</p>
                <div class="mb-4">
                    <label for="fileInputCursos" class="form-label">Archivo Excel</label>
                    <input
                    class="form-control"
                    type="file"
                    id="fileInputCursos"
                    @change="onFileChange"
                    accept=".xlsx, .xls"/>
                </div>
                <div class="d-flex flex-column flex-md-row align-items-center gap-3">
                    <button
                    class="btn btn-primary flex-fill flex-md-grow-1"
                    @click="uploadFile"
                    :disabled="!file">
                    <i class="bi bi-upload me-2"></i> Subir Archivo Excel de Cursos
                </button>
                <a href="https://app.micampusenlinea.com/template/plantillas.rar"
                    target="_blank"
                    class="btn btn-outline-secondary flex-fill flex-md-grow-0"
                    role="button"
                    aria-label="Descargar Plantilla de Cursos">
                    <i class="bi bi-download me-2"></i> Descargar Plantilla
                </a>
                </div>
            </div>
        </div>
        <!-- Tabla Responsiva -->
        <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-4">
            <table class="table table-bordered table-striped mb-0" ref="itemtabla">
                <thead>
                    <tr>
                        <th class="text-center px-3 py-2">ID</th>
                        <th class="text-start px-3 py-2">Nombre</th>
                        <th class="text-start px-3 py-2">Categoría</th>
                        <th class="text-center px-3 py-2">Fecha de Inicio</th>
                        <th class="text-center px-3 py-2">Fecha de Fin</th>
                        <th class="text-center px-3 py-2">Horas</th>
                        <th class="text-center px-3 py-2">Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="curso in cursos" :key="curso.id">
                        <td class="text-center">{{ curso.id }}</td>
                        <td class="text-start">{{ curso.nombre }}</td>
                        <td class="text-start">{{ curso.categoria }}</td>
                        <td class="text-center">{{ curso.fecha_inicio }}</td>
                        <td class="text-center">{{ curso.fecha_fin }}</td>
                        <td class="text-center">{{ curso.horas }}</td>
                        <td td class="text-center p-2">
                            <button @click="verModulos(curso.id)" class="badge badge-sm" style="background-color:#FFD14E">
                                <i class="far fa-folder" aria-hidden="true"></i>
                            </button>
                            <button @click="verMatricula(curso.id)" class="badge badge-sm bg-gradient-primary">
                                <i class="fas fa-user-plus" aria-hidden="true"></i>
                            </button>
                            <button @click="verCurso(curso)" class="badge badge-sm bg-gradient-success">
                                <i class="far fa-eye" aria-hidden="true"></i>
                            </button>
                            <button @click="editarCurso(curso)" class="badge badge-sm bg-gradient-warning">
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </button>
                            <button @click="desactivarCurso(curso.id)" class="badge badge-sm bg-gradient-danger">
                                <i class="far fa-trash-alt" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
        <!-- Modal -->
        <div class="modal fade modal-dark"  id="principalModal" data-bs-backdrop="true" tabindex="-1" aria-labelledby="principalModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <form id="premioForm">
                            <div class="row">
                                <!-- Campos del Formulario -->
                                <div class="col-12 col-sm-6">
                                    <label for="nombre" class="form-label">Nombre</label>
                                    <input type="text" class="form-control" id="nombre" v-model="form.nombre" />
                                    <div class="text-danger">{{ formErrors.nombre }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="codigo_curso" class="form-label">Código Curso</label>
                                    <input type="text" class="form-control" id="codigo_curso" v-model="form.codigo_curso" />
                                    <div class="text-danger">{{ formErrors.codigo_curso }}</div>
                                </div>
                                <div class="col-12">
                                    <label for="descripcion" class="form-label">Descripción</label>
                                    <Editor v-model="form.descripcion" editorStyle="height:320px"/>
                                    <div class="text-danger">{{ formErrors.descripcion }}</div>
                                </div>
                                <div class="col-12">
                                    <label for="requisitos" class="form-label">Requisitos</label>
                                    <Editor v-model="form.requisitos" editorStyle="height:320px"/>
                                    <div class="text-danger">{{ formErrors.requisitos }}</div>
                                </div>
                                <div class="col-12">
                                    <label for="contenido" class="form-label">Contenido</label>
                                    <Editor v-model="form.contenido" editorStyle="height:320px"/>
                                    <div class="text-danger">{{ formErrors.contenido }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="horas" class="form-label">Horas</label>
                                    <input type="number" class="form-control" id="horas" v-model="form.horas" />
                                    <div class="text-danger">{{ formErrors.horas }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="costo" class="form-label">Costo</label>
                                    <input type="number" class="form-control" id="costo" v-model="form.costo" />
                                    <div class="text-danger">{{ formErrors.costo }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="fecha_inicio" class="form-label">Fecha de Inicio</label>
                                    <input type="date" class="form-control" id="fecha_inicio" v-model="form.fecha_inicio" />
                                    <div class="text-danger">{{ formErrors.fecha_inicio }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="fecha_fin" class="form-label">Fecha de Fin</label>
                                    <input type="date" class="form-control" id="fecha_fin" v-model="form.fecha_fin " :min="form.fecha_inicio"
                                    />
                                    <div class="text-danger">{{ formErrors.fecha_fin }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="categoria" class="form-label">Categoría</label>
                                    <v-select :options="categorias" label="nombre" :reduce="categoria => categoria.id" v-model="form.id_categoria" /> 
                                    <div class="text-danger">{{ formErrors.id_categoria }}</div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="cliente" class="form-label">Clientes</label>
                                    <v-select :options="clientes" label="nombres" :reduce="cliente => cliente.id" v-model="form.id_cliente" />  
                                    </div>
                                <div class="col-12">
                                    <label for="docente" class="form-label">Docente</label>
                                    <v-select :options="docentes" label="nombres_completos" :reduce="docente => docente.id" v-model="form.id_docente" /> 
                                </div>
                                <div class="col-12">
                                    <label for="imagen" class="form-label">Imagen</label>
                                    <input type="file" class="form-control" @change="handleFileUpload"/>
                                <div class="text-danger">{{ formErrors.imagen }}</div>
                                <div v-if="form.imagen">
                                    <img :src="form.imagen" alt="Imagen del Curso" width="170" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="guardarCurso">Guardar</button>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
    import Preloader from "../components/Preloader.vue";
    import CategoriaService from '../../services/CategoriaService';
    import "vue-select/dist/vue-select.css";
    import UsuarioService from "../../services/UsuarioService";
    import Clienteservice from "../../services/Clienteservice";
    import CursoService from "../../services/CursoService";
    import $ from 'jquery';
    import Editor from 'primevue/editor';
    import axios from 'axios';

    export default {
        name: "Cursos",
        data() {
            return {
                cursos: [],
                docentes: [],
                clientes: [],
                cargando: false,
                form: {
                    nombre: '',
                    descripcion: '',
                    requisitos: '',
                    contenido: '',
                    nivel: 'Básico',
                    horas: '',
                    fecha_inicio: '',
                    fecha_fin: '',
                    costo: '',
                    estado: 1,
                    id_categoria: '',
                    id_docente: '',
                    id_cliente: '',
                    codigo_curso: '',
                    imagen: '',
                },
                formMatricula: {
                    fecha_inscripcion: '',
                    estado: 1,
                    id_usuario: '',
                    id_registrador: '',
                    id_curso: '',
                    codigo_matricula: '',
                    monto: '',
                },
                formErrors: {},
                file: null,
                modalTitle: "",
                currentCursoId: null,
                
            };
        },
        created() {
            this.fetchCursos();
            this.fecthDocentes();
            this.fecthClientes();
            this.fecthCategorias();
        },
        components: {
            Preloader,
            Editor
        },

        methods: {
            async uploadFile() {
            if (!this.file) return;
            this.cargando = true;
            const formData = new FormData();
            formData.append('file', this.file);
            try {
                const apiUrl = `${process.env.VUE_APP_API_URL}/api/import-cursos`;
                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.$swal({
                    icon: "success",
                    title: "Importación Completa",
                    text: response.data.message || "Los usuarios fueron importados correctamente."
                });
                location.reload();
                // Actualizar la lista de usuarios después de importar
            } catch (error) {
                console.error("Error al importar cursos:", error);
                this.$swal({
                    icon: "error",
                    title: "Error de Importación",
                    text: error.response.data.message || "Ocurrió un error al importar el archivo."
                });
            } finally {
        this.cargando = false; // Finaliza el estado de carga
    }
        },
            verModulos(id) {
                this.$router.push({
                    name: "Modulos",
                    params: {
                        id_curso: id
                    },
                })
            },

            verMatricula(id) {
                this.$router.push({
                    name: "Matricula",
                    params: {
                        id_curso: id
                    },
                })
            },

            async fecthCategorias() {
                try {
                    this.cargando = true;
                    const response = await CategoriaService
                .getCategoriasActivas(); // Llama al servicio de categorías
                    this.categorias = response.data.data; // Almacena las categorías
                } catch (error) {
                    console.error("Error fetching categorias:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar las categorías.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            async fetchCursos() {

                try {
                    this.cargando = true;
                    const response = await CursoService.getCursosActivos();
                    this.cursos = response.data.data;
                /*  this.cursos = cursos.map(curso => {
                    return {
                        ...curso,
                        fecha_inicio: this.convertToISODate(curso.fecha_inicio),
                        fecha_fin: this.convertToISODate(curso.fecha_fin)
                    };
                    });*/
                    this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [
                                [0, 'desc']
                            ],
                            pageLength: 20 // Mostrar 20 filas por página
                        });
                    });

                } catch (error) {
                    console.error("Error fetching cursos:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los cursos.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
        
            async fecthDocentes() {
                try {
                    this.cargando = true;
                    const response = await UsuarioService.getDocentesActivos();
                    this.docentes = response.data.data;

                } catch (error) {
                    console.error("Error fetching docentes:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar las docentes.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            async fecthClientes() {
                try {
                    this.cargando = true;
                    const response = await Clienteservice.getClientesActivos();
                    this.clientes = response.data.data;

                } catch (error) {
                    console.error("Error fetching clientes:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los clientes.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            verCurso(curso) {
                const imagenUrl = process.env.VUE_APP_API_URL +'/storage/'+ curso.imagen;
                const detalles = `
                    <div style="display: flex; flex-wrap: wrap;">
                        <!-- Primera columna -->
                        <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                            <p><strong>Código del Curso:</strong> ${curso.codigo_curso}</p>
                            <p><strong>Nombre del Curso:</strong> ${curso.nombre}</p>
                            <p><strong>Descripción:</strong> ${curso.descripcion}</p>
                            <p><strong>Precio:</strong> ${curso.costo}</p>
                            <p><strong>Duración:</strong> ${curso.horas} horas</p>
                            <p><strong>Nivel:</strong> ${curso.nivel}</p>            
                            <p><strong>Fecha de Inicio:</strong> ${curso.fecha_inicio}</p>
                            <p><strong>Fecha de Fin:</strong> ${curso.fecha_fin}</p>
                            <p><strong>Imagen del Curso:</strong> <img src="${imagenUrl}" alt="${curso.nombre}" width="100"></p>
                        </div>
                        <!-- Segunda columna -->
                        <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                            <p><strong>Estado:</strong> ${curso.estado ? "Activo" : "Inactivo"}</p>
                            <p><strong>Categoría:</strong> ${curso.categoria}</p>
                            <p><strong>Instructor:</strong> ${curso.docente}</p>
                            <p><strong>Creado:</strong> ${curso.created_at}</p>
                            <p><strong>Actualizado:</strong> ${curso.updated_at}</p>
                        </div>
                    </div>
                `;
                this.$swal({
                    icon: "info",
                    title: "Detalles del curso",
                    html: detalles,
                    customClass: {
                        popup: "swal-wide",
                    },
                    width: "650px", // Puedes ajustar el ancho si necesitas más espacio
                });
            },

            editarCurso(curso) {
            const imagenUrl = process.env.VUE_APP_API_URL + '/storage/' + curso.imagen;
                //const imagenUrl = process.env.VUE_APP_API_URL + curso.imagen;
            this.modalTitle = "Editar Curso";
            this.currentCursoId = curso.id;
            this.form = {
                nombre: curso.nombre,
                codigo_curso: curso.codigo_curso,
                descripcion: curso.descripcion,
                imagen: imagenUrl, // Aquí usas la URL completa de la imagen
                estado: curso.estado,
                requisitos: curso.requisitos,
                contenido: curso.contenido,
                horas: curso.horas,
                costo: curso.costo,
                fecha_inicio: curso.fecha_inicio,
                fecha_fin: curso.fecha_fin,
                id_categoria: curso.id_categoria,
                id_cliente: curso.id_cliente,
                id_docente: curso.id_docente,
            };
            this.showModal();
        },
        async desactivarCurso(id) {
                try {
                    await CursoService.desactivarCurso(id);
                    this.$swal({
                        icon: "success",
                        title: "Curso Desactivada",
                        text: "El curso ha sido desactivado",
                    }).then(() => {
                        location.reload();
                    });
                } catch (error) {
                    console.error("Error desactivando el curso:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al desactivar el curso.",
                    });
                }
                
            },
            async guardarCurso() {
                if (this.form.fecha_inicio && this.form.fecha_fin) {
                const fechaInicio = new Date(this.form.fecha_inicio);
                const fechaFin = new Date(this.form.fecha_fin);

                if (fechaFin <= fechaInicio) {
                    this.$swal({
                        icon: "error",
                        title: "Error de Fechas",
                        text: "La fecha de fin debe ser posterior a la fecha de inicio.",
                    });
                    return; // Detener el proceso de guardado
                }
            }
                const formData = new FormData();
                
                // Procesar fechas de manera similar al componente de usuarios
                formData.append("nombre", this.form.nombre);
                formData.append("descripcion", this.form.descripcion);
                formData.append("requisitos", this.form.requisitos);
                formData.append("contenido", this.form.contenido);
                formData.append("nivel", 'Básico');
                formData.append("horas", this.form.horas);
                
                // Conversión de fechas de manera similar a usuarios
                formData.append("fecha_inicio", this.form.fecha_inicio || '');
                formData.append("fecha_fin", this.form.fecha_fin || '');
                
                formData.append("costo", this.form.costo);
                formData.append("estado", this.form.estado);
                formData.append("id_categoria", this.form.id_categoria);
                formData.append("id_docente", parseInt(this.form.id_docente));
                formData.append("id_cliente", this.form.id_cliente);
                formData.append("codigo_curso", this.form.codigo_curso);
                
                if (this.file) {
                    formData.append("imagen", this.file);
                }
                formData.append("estado", this.form.estado);
                this.cargando = true;
                try {
                    if (this.currentCursoId) {
                        formData.append("_method", "PUT");
                        await CursoService.updateCurso(
                            this.currentCursoId,
                            formData
                        );
                        this.$swal({
                            icon: "success",
                            title: "Curso Actualizado",
                            text: "El curso ha sido actualizada exitosamente.",
                        }).then(() => {
                            location.reload();
                            this.closeModal();
                        });
                    } else {
                        await CursoService.createCurso(formData);
                        this.$swal({
                            icon: "success",
                            title: "Curso Registrado",
                            text: "El curso ha sido registrada exitosamente.",
                        }).then(() => {
                            location.reload();
                            this.closeModal();
                        });
                    }
                } catch (error) {
                    if (error.response && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                        this.$swal({
                        icon: "error",
                        title: "Error en los campos",
                        text: "Por favor, revisa los errores en los campos y vuelve a intentarlo.",
                    });
                    } else {
                        console.error("Error guardando categoría:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error",
                            text: error,
                        });
                    }
                } finally {
            this.cargando = false; // Finaliza el estado de carga
        }
            },
            handleFileUpload(event) {
            this.file = event.target.files[0]; // Almacena el archivo de imagen
            },
            nuevoItem() {
                this.modalTitle = "Nuevo Curso";
                this.currentCursoId = null;
                this.form = {
                    nombre: '',
                    descripcion: '',
                    requisitos: '',
                    contenido: '',
                    nivel: 'Básico',
                    horas: '',
                    fecha_inicio: '',
                    fecha_fin: '',
                    costo: '',
                    estado: 1,
                    id_categoria: '',
                    id_docente: '',
                    id_cliente: '',
                    codigo_curso: '',
                    imagen: '',
                };
                this.showModal();
            },
            showModal() {
                const modal = document.getElementById("principalModal");
                modal.classList.add("show");
                modal.style.display = "block";
                modal.setAttribute("aria-modal", "true");
                modal.removeAttribute("aria-hidden");
            },
            closeModal() {
                const modal = document.getElementById("principalModal");
                modal.classList.remove("show");
                modal.style.display = "none";
                modal.setAttribute("aria-hidden", "true");
                modal.removeAttribute("aria-modal");
                this.formErrors = {};
            },
    onFileChange(event) {
        this.file = event.target.files[0];
        console.log("Archivo seleccionado:", this.file);
            console.log("Tipo MIME:", this.file.type); // Muestra el MIME del archivo
            console.log("Nombre:", this.file.name); // Muestra el nombre del archivo
    },
},
};
</script>

<style>
.ck-editor__editable_inline {
    min-height:200px;
}

.ql-editor {
    background-color: white !important; /* Fondo blanco para el editor */
    color: black !important; /* Texto negro */
}

.ql-toolbar {
    background-color: #002162 !important; /* Color claro para la barra de herramientas */
    border: 1px solid #ccc;
}

.ql-container {
    border: 1px solid #ccc;
}

.input-group {
    position: relative; /* Necesario para que el icono se posicione correctamente */
}

.input-group {
    position: relative; /* Necesario para posicionar el icono dentro del contenedor */
}

/* Ajustes de la tabla para pantallas pequeñas */
.table {
    font-size: 0.9rem;
}

@media (max-width: 767px) {
    .table {
    font-size: 0.8rem;
    }
}
.modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
}
@media (max-width: 768px) {
    .modal-dialog {
        max-width: 95%;
    }
}

</style>